import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "communal-treasure",
      "style": {
        "position": "relative"
      }
    }}>{`Communal Treasure`}<a parentName="h1" {...{
        "href": "#communal-treasure",
        "aria-label": "communal treasure permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Panvala is a network of communities who cooerpate around a shared endowment. We have a separate case study available `}<a parentName="p" {...{
        "href": "/tokens/token-studies/pan"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Niran Babalola joined us to discuss the actual tokenomics at work beneath this community treasury (not a treasury tool for DAOs, but an shared endowment for the whole ecosystem), after having described influences like Henry George and Ivan Illich in the fireside video linked below.`}</p>
    <p>{`Participating in Panvala allows communities to create their own matching funds. Instead of just redirecting value to new owners, we can program the flow of value which would otherwise be captured by rent towards what Niran calls "community life". This session will consider `}<strong parentName="p">{`how`}</strong>{` Panvala achieves this and what it really means to focus on community life when we're writing economic code.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`What we're looking to understand is how to implement the kind of economic code which will lead to what Niran calls "enclosure in reverse". You can see this concept hinted at across the Kernel syllabus, in particular when you stumble across some of the `}<a parentName="p" {...{
        "href": "/learn/module-4/#this-week"
      }}>{`quotes from Illich`}</a>{`.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://handbook.panvala.com/the-pan-token/pan-token-economics"
        }}>{`PAN Tokenomics`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=uN4g0Sr3jhs&t=697s"
        }}>{`Niran's KB4 Fireside`}</a>{`.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3979223"
        }}>{`Grounding Decentralised Technologies in Cooperative Principles`}</a>{`, h/t Joe Gerber.`}</li>
      <li parentName="ol">{`Further thoughts along these same lines can be found in the world of `}<a parentName="li" {...{
          "href": "https://elements.disco.coop/"
        }}>{`DISCOs`}</a></li>
    </ol>
    <h2 {...{
      "id": "group-work",
      "style": {
        "position": "relative"
      }
    }}>{`Group Work`}<a parentName="h2" {...{
        "href": "#group-work",
        "aria-label": "group work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You can follow the work we did together when in discussion `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/jcDdhpc0sJvJkPPvLjqS6e/TSG%3A-Panvala"
      }}>{`on this Figma board`}</a>{`.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/nqKrkcWaxOw" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      